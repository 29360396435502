define('youtube', ['youtubePlayer', 'youtubeApi'], function(youtubePlayer, youtubeApi) {

  

  /* 	YOUTUBE Widget 

  	- Use:
  	youtube(.js-youtube-video').done(function(videos) {
  		.. Videos ( array ) ready for further customisation
  	});

  */

  return function($videoContainersSelector, props) {

    var initYoutubeC = function() {
      this.props = props;
      this.videos = [];
      this.setContainers();
      this.youtubeApi = youtubeApi;
    };

    initYoutubeC.prototype = {
      loadApi: function() {
        var apiLoaded = function(yTuneApi) {
          this.createPlayers();
        }.bind(this);

        this.youtubeApi().done(apiLoaded);
      },
      shouldLoadApi: function() {
        return this.$videoContainers && this.$videoContainers.length > 0;
      },
      setContainers: function() {
        this.$videoContainers = document.querySelectorAll($videoContainersSelector);
      },
      returnPlayers: function() {
        if (this.callback && typeof this.callback === 'function') {
          this.callback(this.videos);
        }
      },
      pauseAll: function() {
        for (var i = 0, len = this.videos.length; i < len; i++) {
          this.videos[i].video.pauseVideo();
        }
      },
      stopAll: function() {
        for (var i = 0, len = this.videos.length; i < len; i++) {
          this.videos[i].video.stopVideo();
        }
      },
      isTheatre: function() {
        if (!this.videos.length) {
          return false;
        }
        var is = false;
        for (var i = 0, len = this.videos.length; i < len; i++) {
          if (this.videos[i].isTheatre()) {
            is = true;
          }
        }
        return is;
      },
      loadFirst: function() {
        var loaded = false;
        for (var i = 0, len = this.videos.length; i < len; i++) {
          if (this.videos[i].isTheatre() && !loaded) {
            this.videos[i].loadVideoByDemand();
            loaded = true;
          }
        }
      },
      createPlayers: function() {
        var count = 0;

        for (var i = 0; i < this.$videoContainers.length; i++) {
          var donePlayers = function(video) {
            this.returnPlayers();
            if (this.isTheatre()) {
              this.loadFirst();
            }

          }.bind(this);

          var video = new youtubePlayer(this.$videoContainers[i]);
          this.videos.push(video);
          if ((i + 1) === this.$videoContainers.length) {
            donePlayers();
          }
        }
      },
      done: function(callback) {
        this.callback = callback;
        if (this.shouldLoadApi()) {
          this.loadApi();
        }
      }
    };

    return new initYoutubeC();
  };


});